import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiServer = axios.create({
  // baseURL: 'http://192.168.0.189:8597/smartrun',
 //  baseURL: 'http://localhost:8597/smartrun',
  baseURL: 'https://ranergyapi.mysmartrun.com/smartrun',
  // baseURL: 'http://localhost:3306/smartrun',
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
})

apiServer.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiServer.interceptors.response.use(undefined, errorin => {
  // Errors handling
  console.log(`Damn error ${errorin}`)
  const { response } = errorin
  const { error } = response.data
  if (error) {
    if (error !== 'Bad Request') {
      notification.warning({
        message: error,
      })
    }
  }
})

export default apiServer
