import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import store from 'store'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  console.log(`Login Saga: ${success}`)
  if (success) {
    if (success.jwttoken) {
      // yield put({
      //  type: 'user/LOAD_CURRENT_ACCOUNT',
      // })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: success.masterUserInfo[0].userId,
          name: success.masterUserInfo[0].userFirstName,
          plantcode: success.masterUserInfo[0].masterPlantCode,
          plantType: success.masterUserInfo[0].plantMaster.masterPlantTypeCode,
          email: '',
          role: success.masterUserInfo[0].userRoleCode,
          roleDesc: 'Admin',
          groupCode: success.masterUserInfo[0].masterUserGroupCode,
          authorized: true,
        },
      })
      const menuData = JSON.parse(success.menuOptions)
      console.log(`Menu :${menuData}`)
      yield store.set(`app.menu`, menuData)
      yield put({
        type: 'menu/SET_STATE',
        payload: {
          menuData,
        },
      })
      yield history.push('/')
      notification.success({
        message: 'Logged In',
        description: `Welcome Back ! ${success.masterUserInfo[0].userFirstName}`,
      })
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.success({
        message: 'Logged Failed',
        description: success.returnMsg,
      })
    }
  }
  if (!success) {
    notification.success({
      message: 'Login Failed',
      description: 'Something went wrong! Check your internet connection.',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(`inside auth sate`)
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    // const { id, email, name, avatar, role } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: response.masterUserInfo[0].userId,
        name: response.masterUserInfo[0].userFirstName,
        plantcode: response.masterUserInfo[0].masterPlantCode,
        plantType: response.masterUserInfo[0].plantMaster.masterPlantTypeCode,
        email: '',
        role: response.masterUserInfo[0].userRoleCode,
        roleDesc: 'Admin',
        groupCode: response.masterUserInfo[0].masterUserGroupCode,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}
export function* SET_PLANTCODE(arg) {
  yield put({
    type: 'user/SET_PLANTCODE',
    payload: arg.payload,
  })
  console.log('redirect!')
  history.push('/dashboard/plantdashboard')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SET_PLANT_CODE_STATE, SET_PLANTCODE),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
