import apiClient from 'services/axios'
import serverApi from 'services/serverApi'
import store from 'store'

export async function login(email, password) {
  return serverApi
    .post(`authenticate`,
      {
        userName: email,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      })
    .then(response => {
      console.log(`Login Response: ${response}`)
      if (response) {

        const { jwttoken } = response.data
        const { userId } = response.data.masterUserInfo[0]
        if (jwttoken) {

          store.set('accessToken', jwttoken)
          store.set('accessId', userId)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  console.log(store.get('accessToken'))
  return serverApi
    .post(`refreshtoken`,
      {
        token: store.get('accessToken') || '',
        userId: store.get('accessId') || ''
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      })
    .then(response => {
      console.log(`Refresh Response: ${response}`)
      if (response) {
        const { jwttoken } = response.data
        if (jwttoken) {
          store.set('accessToken', jwttoken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}
