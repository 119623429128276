import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Dashboards
  {
    path: '/dashboard/plantdashboard',
    Component: lazy(() => import('pages/dashboard/Plantdashboard')),
    exact: true,
  },
  {
    path: '/dashboard/customerportfolio',
    Component: lazy(() => import('pages/dashboard/portfolioview')),
    exact: true,
  },

  // TileView
  {
    path: '/ftptransfer/ftptransfer',
    Component: lazy(() => import('pages/tileView/Card')),
    exact: true,
  },

  // Reports
  {
    path: '/report/misreport',
    Component: lazy(() => import('pages/reports/misReports')),
    exact: true,
  },

  {
    path: '/report/datavizreport',
    Component: lazy(() => import('pages/reports/datavizreport')),
    exact: true,
  },

  {
    path: '/alarm/alarmslist',
    Component: lazy(() => import('pages/alarm/alarmLog')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/admin/usermanagement',
    Component: lazy(() => import('pages/admin/usermanagement')),
    exact: true,
  },
  {
    path: '/report/adjustment',
    Component: lazy(() => import('pages/reports/adjustment-screens')),
    exact: true,
  },
  {
    path: '/asset/createassert',
    Component: lazy(() => import('pages/assert/createAssert')),
    exact: true,
  },
  {
    path: '/master/siteonboarding',
    Component: lazy(() => import('pages/onboardMaster/masterscreen')),
    exact: true,
  },
  {
    path: '/ldc/plantstatus',
    Component: lazy(() => import('pages/LDC/plantStatus')),
  },
  {
    path: '/ldc/plantdetails',
    Component: lazy(() => import('pages/LDC/plantDetails')),
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  groupCode: user.groupCode,
})

const Router = ({ history, routerAnimation, groupCode }) => {
  console.log(`groupCode ${groupCode}`)
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {console.log(groupCode)}
                    <Route
                      exact
                      path="/"
                      render={() => {
                        return groupCode !== '' && groupCode !== null && groupCode === 'MG003' ? (
                          <Redirect to="/dashboard/plantdashboard" />
                        ) : (
                          <Redirect to="/dashboard/customerportfolio" />
                        )
                      }}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
